// format the images based on one photo
export default function formatOgImage(photo, fallbackAlt?: string) {
  if (!photo) return undefined
  const idealW = 1200
  const idealH = 630
  const isPhotoSmaller = photo.width && photo.height && (photo.width < idealW || photo.height < idealH)
  const imageW = isPhotoSmaller ? photo.width : idealW
  const imageH = isPhotoSmaller ? photo.height : idealH

  return {
    url: `${photo.url}?w=${imageW}&q=75&h=${imageH}&fm=jpg`,
    width: imageW,
    height: imageH,
    alt: fallbackAlt
  }
}
