import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGemail = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M50 19.9484V45H0V19.987V15.0386V5H50V15V19.9484ZM45 19.9484V40H5V19.987H6.665C7.332 19.989 7.959 20.2473 8.436 20.7214L19.704 31.8326C21.12 33.231 23 34 25 34H25.01C27.013 33.998 28.896 33.2231 30.305 31.8247L41.591 20.6947C42.051 20.2265 42.695 19.9544 43.331 19.9484H45ZM45 15V10H5V15.0386H6.67C8.677 15.0435 10.558 15.8185 11.971 17.2209L23.225 28.3202C23.707 28.7952 24.334 29.0516 25 29.0516H25.003C25.671 29.0506 26.299 28.7923 26.771 28.3251L38.036 17.2159C39.393 15.8363 41.324 15.0188 43.306 15H45Z'
      />
    </svg>
  )
}

export default SVGemail
