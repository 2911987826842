import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGeye = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 20 13'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path d='M10 0.25C5.63812 0.25 1.8575 2.79 0 6.5C1.8575 10.21 5.63812 12.75 10 12.75C14.3619 12.75 18.1419 10.21 20 6.5C18.1425 2.79 14.3619 0.25 10 0.25ZM14.9306 3.56438C16.1056 4.31375 17.1012 5.3175 17.8494 6.5C17.1012 7.68188 16.1056 8.68625 14.9306 9.43563C13.4544 10.3775 11.7494 10.875 10 10.875C8.25062 10.875 6.54562 10.3775 5.06938 9.43563C3.89438 8.68625 2.89875 7.6825 2.15062 6.5C2.89875 5.31812 3.89438 4.31375 5.06938 3.56438C5.14563 3.51563 5.22313 3.46813 5.30125 3.42188C5.10688 3.95563 5 4.53188 5 5.1325C5 7.89375 7.23875 10.1325 10 10.1325C12.7612 10.1325 15 7.89375 15 5.1325C15 4.53125 14.8937 3.95563 14.6987 3.42188C14.7762 3.46813 14.8538 3.51563 14.9306 3.56438ZM10 4.625C10 5.66062 9.16063 6.5 8.125 6.5C7.08938 6.5 6.25 5.66062 6.25 4.625C6.25 3.58938 7.08938 2.75 8.125 2.75C9.16063 2.75 10 3.58938 10 4.625Z' />
    </svg>
  )
}

export default SVGeye
