import { AppContext } from '@context/AppContext'
import { LanguageContext, LanguagePathMap } from '@context/LanguageContext'
import formatOgImage from 'components/shared/utils/formatOgImage'
import getCanonicalUrls from 'components/shared/utils/getCanonicalUrl'
import {
  BreadCrumbJsonLdProps,
  BreadcrumbJsonLd, NextSeo, OrganizationJsonLd, WebPageJsonLd
} from 'next-seo'
import Head from 'next/head'
import { useContext } from 'react'
import organizationJsonLdProps from './Schemas/organizationJsonLdProps'
import { ISeo } from './Seo.type'
import fallbackSeo from './fallback.data'

export function Seo({
  seo,
  fallbackTitle,
  fallbackDescription = '',
  slug,
  showSpanishPage,
  ogType,
  fallbackImg,
  fallbackImgAlt,
  extraOg,
  twitter,
  structuredData,
  breadcrumb = []
}: ISeo) {
  const { language } = useContext(LanguageContext)
  const { basePath } = useContext(AppContext)
  const locale = LanguagePathMap.get(language)
  const { canonical } = getCanonicalUrls({ locale, basePath, path: slug })

  const isSpanish = locale === 'es-mx'
  let altLocale = ''
  if (isSpanish) altLocale = 'en'
  else if (showSpanishPage) altLocale = 'es-mx'

  const breadcrumbJsonLd: BreadCrumbJsonLdProps = {
    itemListElements: breadcrumb.map((item, index) => ({
      position: index + 1,
      name: item.name,
      item: `${process.env.NEXT_PUBLIC_APP_DOMAIN}${isSpanish ? '/es-mx' : ''}/${item.path}`
    }))
  }

  const finalTitle = (fallbackTitle || fallbackSeo[locale].title)
    .replace('| Understood', '').trim()

  const finalDescription = (seo?.seoDescription
    || (fallbackDescription && fallbackDescription.length > 160 && `${fallbackDescription ? fallbackDescription.slice(0, 155) : ''}...`))
    || fallbackDescription
    || fallbackSeo[locale]?.description

  const imgChosen = seo?.seoImage || fallbackImg || fallbackSeo[locale].image
  const finalImage = formatOgImage(imgChosen, fallbackImgAlt)

  const og = {
    type: ogType || 'website',
    url: canonical,
    title: finalTitle,
    description: finalDescription,
    images: [finalImage],
    siteName: 'Understood',
    ...extraOg
  }

  // Build the alternates languages urls
  const getLanguageAlternates = (href: string, alternateLocale?: string) => {
    return []
    // it is already applied in _app.tsx so it would be doubled with this too
    // however it is ready to use
    // when done applying the component, remove the ones from bp/src/pages/_app.tsx

    const languageRegex = /\/(en|es-mx)\//
    const url = href.replace(languageRegex, `/${alternateLocale}/`)
    return [
      {
        hrefLang: altLocale === 'en' ? 'en-US' : 'es-MX',
        href: url
      },
      {
        hrefLang: altLocale === 'en' ? 'es-MX' : 'en-US',
        href
      }
    ]
  }

  // Build twitter data with default site
  // FYI Twitter will read the og:title, og:image and og:description tags for their card.
  // next-seo omits twitter:title, twitter:image and twitter:description to avoid duplication.
  const twitterData = {
    ...twitter,
    site: '@UnderstoodOrg',
    cardType: twitter?.cardType || 'summary'
  }

  return (
    <>
      <Head>
        <meta
          name='viewport'
          content='width=device-width,minimum-scale=1,initial-scale=1'
        />
      </Head>
      <NextSeo
        title={finalTitle}
        description={finalDescription}
        canonical={canonical}
        languageAlternates={getLanguageAlternates(canonical, altLocale)}
        openGraph={og}
        twitter={twitterData}
        noindex={seo?.noIndex}
        nofollow={seo?.noFollow}
      />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <OrganizationJsonLd {...organizationJsonLdProps} />

      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      {!!breadcrumbJsonLd.itemListElements.length && <BreadcrumbJsonLd {...breadcrumbJsonLd} />}

      {structuredData ? (
        structuredData(finalTitle, finalDescription, imgChosen)
      ) : (
        <WebPageJsonLd description={finalDescription || finalTitle} id={canonical} />
      )}
    </>
  )
}

export default Seo
