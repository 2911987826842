import { OrganizationJsonLdProps } from 'next-seo'

const organizationJsonLdProps: OrganizationJsonLdProps = {
  name: 'Understood for All Inc',
  taxID: '83-2365235',
  url: 'www.understood.org',
  address: {
    addressLocality: 'New York',
    addressRegion: 'New York',
    postalCode: '10014',
    streetAddress: '96 Morton Street, Floor 5'
  },
  areaServed: 'USA',
  award: ['Gold Winner at the 2023 Viddy Awards'],
  logo: 'https://images.ctfassets.net/p0qf7j048i0q/307jFAYQevghG8nQMCjh89/ccdbbec895765824db43f6d30b496e83/logo_lockup_navy.jpg',
  description:
    // eslint-disable-next-line max-len
    'Understood is a nonprofit dedicated to shaping the world for difference. We provide resources and support so people who learn and think differently can thrive — in school, at work, and throughout life.',
  email: 'media@understood.org',
  founder: [
    {
      // eslint-disable-next-line max-len
      '@type': 'Person',
      image:
        'https://images.ctfassets.net/p0qf7j048i0q/1TH7U8mp119EInJs71P0t1/8a5d821d69264345a3c908cd63a7b90b/Fred_Poses.png',
      name: 'Fred Poses',
      sameAs: 'https://www.linkedin.com/in/fredposes/'
    },
    {
      // eslint-disable-next-line max-len
      '@type': 'Person',
      image:
        'https://images.ctfassets.net/p0qf7j048i0q/1B5hkOvEIWfzy3WVZpRVQs/5e8ef143376463820f2f418d29ff187d/Nancy_Poses.png',
      name: 'Nancy Poses'
    }
  ],
  sameAs: ['https://www.linkedin.com/company/understood/']
}

export default organizationJsonLdProps
