import SVGeye from '@fractal/primitives/SVGs/icons/eye'
import SVGeyeBlocked from '@fractal/primitives/SVGs/icons/eyeBlocked'
import React, { useEffect, useRef, useState } from 'react'
import { useFormControlContext } from '../FormControl'
import { InputProps } from './Input.interface'
import styles from './Input.module.scss'

const Input = (props: InputProps) => {
  if (props.type === 'checkbox') {
    throw new Error('Use Checkbox component instead')
  }

  if (props.type === 'password') {
    return <InputPassword {...props} />
  }

  const { updateContext, validationError } = useFormControlContext()
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    updateContext({ control: { ...props } })

    if (props.focus) {
      inputRef.current?.focus()
    }
  }, [props])

  const errorMessageID = (props.id || props.name) + '_error'

  // code bellow is needed because react doesn't remove aria-invalid when false
  // and the css selector we current use, selects the input[aria-invalid]
  // move the code to StyledInput when using tokens e.g. <StyledInput {...props} aria-invalid={error} aria-describedby={errorMessageID} />
  let ariaProps = { 'aria-required': !!props.required }
  if (validationError) {
    ariaProps['aria-invalid'] = true
    ariaProps['aria-describedby'] = errorMessageID
  }

  return (
    <input
      {...props}
      {...ariaProps}
      ref={inputRef}
      className={`form-control ${
        props.controlSize === 'large' ? 'form-control--tall' : ''
      } ${props.noBorder ? 'form-control--noborder' : ''} ${props.className}`}
    />
  )
}

const InputPassword = (props: InputProps) => {
  const [type, setType] = useState('password')
  const inputRef = useRef<HTMLInputElement>(null)
  const { updateContext, validationError } = useFormControlContext()

  useEffect(() => {
    updateContext({ control: { ...props } })

    if (props.focus) {
      inputRef.current?.focus()
    }
  }, [props])

  if (type === 'checkbox') {
    throw Error('Use Checkbox component instead')
  }

  const handleIconClick = () => {
    const newType = type === 'text' ? 'password' : 'text'
    setType(newType)
    inputRef.current?.focus()
  }

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.selectionStart = inputRef.current.value.length
      inputRef.current.selectionEnd = inputRef.current.value.length
    }
  }, [type])

  const errorMessageID = (props.id || props.name) + '_error'

  // code bellow is needed because react doesn't remove aria-invalid when false
  // and the css selector we current use, selects the input[aria-invalid]
  // move the code to StyledInputPassword when using tokens
  // e.g. <StyledInputPassword {...props} aria-invalid={error} aria-describedby={errorMessageID} type={type} ref={inputRef} />
  let ariaProps = { 'aria-required': !!props.required }
  if (validationError) {
    ariaProps['aria-invalid'] = true
    ariaProps['aria-describedby'] = errorMessageID
  }

  return (
    <div className='p-sm-relative'>
      <input
        {...props}
        {...ariaProps}
        type={type}
        ref={inputRef}
        className={`form-control pr-sm-6 ${
          props.controlSize === 'large' ? 'form-control--tall' : ''
        } ${props.noBorder ? 'form-control--noborder' : ''} ${props.className}`}
      />
      <button
        type='button'
        data-testid={props['data-testid'] + '_toggle'}
        onClick={handleIconClick}
        aria-controls={props.id}
        aria-label={type === 'password' ? 'Show password' : 'Hide password'}
        className={`Btn-link p-sm-absolute right-sm-4 ${styles.passwordViewToggle}`}
      >
        {type === 'password' ? (
          <SVGeyeBlocked className='vicon fill-svg' />
        ) : (
          <SVGeye className='vicon fill-svg' />
        )}
      </button>
    </div>
  )
}

Input.defaultProps = {
  controlSize: 'default',
  noBorder: false
}

export default Input
