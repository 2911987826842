import { AnalyticsContext, INavigationEvent } from '@context/AnalyticsContext'
import React, { createContext, useContext, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

export interface ITrackItemContext
  extends Omit<
    INavigationEvent,
    'eventType' | 'eventHorizontalPosition' | 'eventVerticalPosition'
  > { }

export interface ITrackImpressionProps extends ITrackItemContext {
  children?: any
  className?: string
  as?: string
  onFireEvent?: () => void
}

export const TrackItemContext = createContext<ITrackItemContext>({
  buttonName: '',
  unitName: '',
  unitLocation: 'inline'
})

export const useTrackItem = () => {
  return useContext(TrackItemContext)
}

const getPositionAsNumber = (position): number => {
  if (typeof position === 'number') {
    return position
  } else if (typeof position === 'string') {
    return parseInt(position)
  }

  return 1
}

export const TrackImpression: React.FC<ITrackImpressionProps> = ({
  as = 'div',
  onFireEvent,
  children,
  className,
  ...props
}) => {
  const [ref, inView, entry] = useInView({ triggerOnce: true, threshold: 0 })
  const { trackNavigation } = useContext(AnalyticsContext)

  useEffect(() => {
    if (inView) {
      const viewportOffset = entry?.target?.getBoundingClientRect()
      trackNavigation({
        ...props,
        eventType: 'impression',
        eventHorizontalPosition: viewportOffset?.left || window.scrollX,
        eventVerticalPosition: viewportOffset?.top || window.scrollY,
        positionInUnit: getPositionAsNumber(props?.positionInUnit)
      })
      onFireEvent?.()
    }
  }, [inView])

  return (
    <TrackItemContext.Provider
      value={{
        ...props
      }}
    >
      {as === 'fragment' ? (
        <>
          {children}
          <span ref={ref}></span>
        </>
      ) : (
        React.createElement(as, {
          className,
          ref,
          children
        })
      )}
    </TrackItemContext.Provider>
  )
}

export default TrackImpression
