import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGeyeBlocked = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 20 21'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path d='M18.4754 0.940105C18.1092 0.574306 17.5161 0.574306 17.1498 0.940105L13.2011 4.88399C12.1867 4.57687 11.1123 4.41145 9.99981 4.41145C5.63793 4.41145 1.85731 6.94832 -0.000191035 10.6538C0.802934 12.2555 1.96543 13.6388 3.37918 14.6938L0.274184 17.795C-0.092066 18.1607 -0.092066 18.7531 0.274184 19.1189C0.457309 19.3018 0.697309 19.393 0.937309 19.393C1.17731 19.393 1.41731 19.3018 1.60043 19.1189L18.4754 2.26472C18.8417 1.89892 18.8417 1.3059 18.4754 0.94073V0.940105ZM8.12481 6.90837C8.94981 6.90837 9.64981 7.44084 9.90043 8.17993L7.52293 10.5545C6.78293 10.3042 6.24981 9.60505 6.24981 8.78106C6.24981 7.74671 7.08918 6.90837 8.12481 6.90837ZM2.15043 10.6538C2.89856 9.47333 3.89418 8.4702 5.06918 7.72174C5.14543 7.67305 5.22293 7.62561 5.30106 7.57942C5.10668 8.11251 4.99981 8.68805 4.99981 9.28794C4.99981 10.3585 5.33731 11.3504 5.91168 12.1638L4.72168 13.3523C3.69731 12.6338 2.82293 11.7162 2.15043 10.6538Z' />
      <path d='M15 9.28876C15 8.75878 14.9169 8.24816 14.7637 7.76938L8.47874 14.0466C8.95874 14.1996 9.46999 14.2826 10.0006 14.2826C12.7619 14.2826 15.0006 12.0466 15.0006 9.28876H15Z' />
      <path d='M16.2112 6.32303L14.8562 7.67636C14.8812 7.69197 14.9056 7.70695 14.93 7.72255C16.105 8.47101 17.1006 9.47352 17.8487 10.6546C17.1006 11.835 16.105 12.8381 14.93 13.5866C13.4537 14.5273 11.7487 15.0242 9.99937 15.0242C9.24437 15.0242 8.49749 14.9312 7.77374 14.7501L6.27187 16.2502C7.43812 16.669 8.69249 16.8969 9.99937 16.8969C14.3606 16.8969 18.1412 14.36 19.9994 10.6546C19.12 8.90048 17.8094 7.40856 16.2106 6.32365L16.2112 6.32303Z' />
    </svg>
  )
}

export default SVGeyeBlocked
