import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGtwitter = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.96933 2.39862L21.616 27.3305L2.85181 47.6014H7.07521L23.5036 29.8536L36.7768 47.6014H51.1482L31.452 21.2674L48.9178 2.39862H44.6944L29.5652 18.7435L23.4529 10.5711L17.3407 2.39862H2.96933ZM9.18002 5.50924H15.7822L44.9367 44.4908H38.3345L9.18002 5.50924Z'
      />
    </svg>
  )
}

export default SVGtwitter
