/* eslint-disable max-len */

const fallbackSeo = {
  en: {
    title: 'Understood - For Learning and Thinking Differences',
    description: 'Understood.org is the resource that helps the 70 million people with learning and thinking differences anticipate the challenges, barriers, or opportunities in life and confidently reach their potential.',
    image: { url: 'https://images.ctfassets.net/p0qf7j048i0q/2L1uKMQY7R5x3VWlLwLzja/0002d8e3f97d4c11f682e7fc26f22d53/metaImage_Overview.png', width: 1200, height: 900 }
  },
  'es-mx': {
    title: 'Understood - Para las diferencias en la manera de pensar y aprender',
    description: 'Understood.org es el recurso que ayuda a los 70 millones de personas con diferencias en la manera de pensar y aprender a anticipar los desafíos, obstáculos y oportunidades en la vida y alcanzar su potencial con seguridad.',
    image: { url: 'https://images.ctfassets.net/p0qf7j048i0q/2L1uKMQY7R5x3VWlLwLzja/0002d8e3f97d4c11f682e7fc26f22d53/metaImage_Overview.png', width: 1200, height: 900 }
  }
}
export default fallbackSeo
